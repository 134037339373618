import React from 'react'

import {
  Button,
  Buttons,
  Columns,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  ModalBody,
  ModalContent,
  Paragraph,
  Spacer,
  Strong,
  Textarea,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Keskeytä-toiminto">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          <Strong>Keskeytä</Strong>-toiminnolla keskeytetään lomakkeen
          täyttäminen.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Strong>Keskeytä</Strong>-toimintoa käytetään lomakkeilla, jotka
              ovat käyttäjän aloittamia tapahtumia. Käyttäjä voi siis muuttaa
              mielensä ja keskeyttää tapahtuman (esim.{' '}
              <Strong>Työnhaun aloitus</Strong>).
            </ListItem>
            <ListItem>
              <Strong>Keskeytä</Strong>-toimintoa ei voi olla lomakkeilla,
              joissa vaaditaan käyttäjän reagoimista eikä
              peruuttamismahdollisuutta ole (esim.{' '}
              <Strong>Työttömyysetuusasian selvityspyyntö</Strong>).
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Strong>Keskeytä</Strong>-toiminto on painike lomakkeen alaosassa.
          Painikkeen painamisen jälkeen esitetään varmistusdialogi (
          <Code>
            <Link page="Modal" />
          </Code>
          ), jolla hyväksytään keskeyttäminen tai peruutetaan takaisin
          lomakkeelle.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Paragraph>
        <Strong>Keskeytä</Strong>-dialogi on geneerinen ja esitetään kaikkialla
        samanlaisena.
      </Paragraph>
      <NoteItem
        bullet="1"
        title="Keskeytä-painike lomakkeella"
      />
      <NoteItem
        bullet="2"
        title="Varmistusdialogin otsikko"
      />
      <NoteItem
        bullet="3"
        title="Varmistusdialogin sisältö"
      />
      <NoteItem
        bullet="4"
        title="Varmistusdialogin painikkeet"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <Strong>Peruuta</Strong>-toiminto palaa takaisin lomakkeelle.{' '}
            <Strong>Keskeytä</Strong>-toiminto tyhjää lomakkeen ja sulkee
            lomakkeen. Myös mahdollinen aiheeseen liittyvä tapahtumavirtakortti
            poistetaan.
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample
        notes={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]}
      >
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lorem ipsum dolor sit amet" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer
              marginTop="xl"
              marginBottom="layout-xl"
            >
              <Buttons align="center">
                <div data-note="1">
                  <Button
                    iconLeft={<XIcon />}
                    variant="plain"
                  >
                    Keskeytä
                  </Button>
                </div>
              </Buttons>
            </Spacer>
            <ModalContent size="lg">
              <ModalBody>
                <div data-note="2">
                  <Heading
                    id="modal-heading"
                    size={4}
                  >
                    Haluatko keskeyttää?
                  </Heading>
                </div>
                <div data-note="3">
                  <Paragraph>
                    Jos keskeytät, täyttämäsi tiedot poistetaan.
                  </Paragraph>
                </div>
                <div data-note="4">
                  <Buttons
                    align="right"
                    noMargin
                  >
                    <Button variant="outline">Peruuta</Button>
                    <Button color="danger">Keskeytä</Button>
                  </Buttons>
                </div>
              </ModalBody>
            </ModalContent>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <List variant="unordered">
        <ListItem>
          <Strong>Työnhaun aloitus ja muutos</Strong> -lomakkeilla on{' '}
          <Strong>Keskeytä</Strong>-toiminnot. Käyttäjä aloittaa itse tekemään
          työnhaun aloitusta tai muutosta Työmarkkinatorin asioinnissa. Hän voi
          myös muuttaa mielensä ja keskeyttää toiminnon.
        </ListItem>
        <ListItem>
          <Strong>Työttömyysetuusasian selvityspyyntö</Strong> -lomakkeella ei
          ole <Strong>Keskeytä</Strong>-toimintoa. Käyttäjä on saanut
          Selvityspyynnön täytettäväksi viranomaiselta eikä näin ollen voi
          keskeyttää toimintoa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
